import { CROSS_LONGITUDE_GRAPH } from "./constants";

export const localeString = {
  projects: {
    projectInfo: {
      ko: "프로젝트 정보",
      en: "Project Information",
    },
    application: {
      ko: "어플리케이션",
      en: "Applications",
    },
    projectList: {
      ko: "프로젝트 리스트",
      en: "Project List",
    },
    checkingApplication: {
      ko: "2D(이미지) & 3D(모델) 기반 점검",
      en: "2D(Image) & 3D(Model) Inspection",
    },
    portinfraApplication: {
      ko: "항만 인프라 안전점검",
      en: "Port Infrastructure Inspection",
    },
    slopeAppication: {
      ko: "비탈면 유지관리",
      en: "Cut Slope Maintenance",
    },
    constructingApplication: {
      ko: "항만 시설물 안전점검",
      en: "Construction Site Management",
    },
    cadastralApplication: {
      ko: "용지도 관리(Beta)",
      en: "Land Map Management(Beta)",
    },
    managingDroneImages: {
      ko: "드론 이미지",
      en: "UAV Image",
    },
    droneImages: {
      ko: "드론 이미지 매핑",
      en: "UAV Image Mapping Process",
    },
    summaryMapping: {
      ko: "이미지 매핑 요약",
      en: "Processing Summary",
    },
    waitingMapping: {
      ko: "매핑 대기",
      en: "Before Mapping Process",
    },
    managingProjectGroup: {
      ko: "프로젝트 그룹",
      en: "Grouping Projects",
    },
    managingEtcData: {
      ko: "기타 데이터",
      en: "Additional Data",
    },
    roadIssue: {
      ko: "도로 포장 이슈 탐지",
      en: "Road pavement issue detection",
    },
    newProject: {
      ko: "새 프로젝트",
      en: "New Project",
    },
    droneImagesCount: {
      ko: "드론이미지수",
      en: "Image Number",
    },
    droneImageMapping: {
      ko: "이미지 매핑",
      en: "Image Mapping",
    },
    imageMapingProcess: {
      ko: "이미지 매핑 프로세스",
      en: "Image Mapping Process",
    },
    processCondition: {
      ko: "처리 상태",
      en: "Processing Status",
    },
    orthomosaicDown: {
      ko: "정사영상[TIF]",
      en: "Orthomosaic Map[TIF]",
    },
    pointcloudDown: {
      ko: "3D 포인트 클라우드[LAS]",
      en: "3D Point Clouds[LAS]",
    },
    meshDown: {
      ko: "3D 매쉬[OBJ]",
      en: "3D Mesh[OBJ]",
    },
    dsmDown: {
      ko: "수치표면모델[DSM-TIF]",
      en: "Digital Surface Model[TIF]",
    },
    dataDown: {
      ko: "이미지 매핑 결과물 다운로드",
      en: "Downloading Image Mapping Results",
    },
    message: {
      createdContent: {
        ko: "게시물 생성 완료",
        en: "Post Creation Completed",
      },
      failedCreatingContent: {
        ko: "게시물 생성 실패",
        en: "Post Creation Failed",
      },
      modifiedContent: {
        ko: "게시물 수정 완료",
        en: "Post Editing Completed",
      },
      failedModifiedContent: {
        ko: "게시물 수정 실패",
        en: "Post Editing Failed",
      },
      deletedContent: {
        ko: "게시물 삭제 완료",
        en: "Post Deletion Completed ",
      },
      failedDeletedContent: {
        ko: "게시물 삭제 실패",
        en: "Post Deletion Failed",
      },
      makeSureDeleteProject: {
        ko: "프로젝트를 삭제 하시겠습니까?",
        en: "Are you sure you would like to delete the project?",
      },
      processingMapping: {
        ko: "드론 이미지 매핑 처리가 완료되면 확인할 수 있습니다",
        en: "You are able to check it when the image mapping process is completed",
      },
      processingMappingDownload: {
        ko: "드론 이미지 매핑 처리가 완료되면 데이터를 다운로드 받을 수 있습니다",
        en: "You are allowed to download those files when the image mapping process is completed",
      },
      accessDeniedAdmin: {
        ko: "어드민 계정은 접근할 수 없습니다",
        en: "Administration account cannot be accessed",
      },
      makeSureModifyProject: {
        ko: "프로젝트를 수정 하시겠습니까?",
        en: "Are you sure you would like to edit the project?",
      },
      makeSureDeleteFolder: {
        ko: "폴더를 삭제하시겠습니까?",
        en: " Are you sure you would like to delete the folder",
      },
      successUpload: {
        ko: "업로드 완료",
        en: "Uploading Completed",
      },
      failedUpload: {
        ko: "업로드 실패",
        en: "Uploading Failed",
      },
      uploadedImage: {
        ko: "업로드 된 이미지",
        en: "Uploaded images",
      },
      refreshAfterUploaded: {
        ko: "업로드를 마치면 새로고침 버튼을 눌러주세요.",
        en: "Please refresh section through a button",
      },
      imageDeleteCompleted: {
        ko: "이미지 삭제가 완료되었습니다.",
        en: "Image was deleted",
      },
      imageUpload10: {
        ko: "이미지가 10장 이상 업로드되어야 합니다.",
        en: "At least 10 images must be uploaded.",
      },
      notSelectedImage: {
        ko: "이미지를 선택 해주세요.",
        en: "Nothing was selected",
      },

      deleteCompleted: {
        ko: "삭제에 성공했습니다.",
        en: "deleted",
      },
      failedDeleting: {
        ko: "삭제에 실패했습니다.",
        en: "Failed Delete",
      },
    },
  },
  auth: {
    message: {
      loginFailed: {
        ko: "로그인 실패",
        en: "Login failed.",
      },
      confirmFailed: {
        ko: "인증 실패",
        en: "Confirmation is Failed",
      },
      resendingConfirmationCode: {
        ko: "인증 코드를 재발송 했습니다.",
        en: "Confirmation code is resend",
      },
      confirmationCodeResendingFail: {
        ko: "재전송 실패",
        en: "Resending is Failed",
      },
      fillEmail: {
        ko: "기존에 가입하신 이메일을 입력해주세요.",
        en: "Please enter the email you used for registration.",
      },
      sendCodeNumber: {
        ko: "인증번호를 보내드립니다.",
        en: "We will send you an authentication code.",
      },
      sentCodeNumber: {
        ko: "인증번호가 발송되었습니다.",
        en: "The authentication code has been sent.",
      },
      sendCodeNumberFailed: {
        ko: "인증번호 전송 실패",
        en: "Failed to send authentication code.",
      },
      changePassWordFailed: {
        ko: "비밀번호 변경 실패",
        en: "Failed to change password.",
      },
      notMatchCode: {
        ko: "인증번호는 6자리 숫자입니다.",
        en: "The authentication code consists of six digits.",
      },
    },
  },

  inspection2d: {
    projectInfo: {
      ko: "프로젝트 정보",
      en: "Project Information",
    },
    projectModify: {
      ko: "프로젝트 정보 수정",
      en: "Editing",
    },
    layerInfo: {
      ko: "레이어",
      en: "Layers",
    },
    issueInfo: {
      ko: "이슈/측정",
      en: "Annotations",
    },
    managingBim: {
      ko: "3D 관리",
      en: "BIM Management",
    },
    report: {
      ko: "리포트",
      en: "Report",
    },
    mainLayer: {
      ko: "주 레이어",
      en: "Primary Layer",
    },
    issueType: {
      ko: "이슈 툴",
      en: "Tools",
    },
    issueName: {
      ko: "이슈 이름",
      en: "Name",
    },
    createIssue: {
      ko: "이슈 생성",
      en: "Confirmation",
    },
    typeByXY: {
      ko: "X-Y 좌표",
      en: "X-Y Coordinate",
    },
    typeByLatLng: {
      ko: "경위도 좌표",
      en: "Lat-Long Coordinate",
    },
    issueList: {
      ko: "이슈 사항",
      en: "Types",
    },
    issueDegree: {
      ko: "이슈 정도",
      en: "Quantity Level",
    },
    msg: {
      tooSmallArea: {
        ko: "체적 정보를 구하기 위한 너비가 너무 좁습니다.",
        en: "Too small area for calculate.",
      },

      notAnyMatchIssue: {
        ko: "일치하는 이슈가 없습니다.",
        en: "There are no matching issues.",
      },
      makeIssue: {
        ko: "이슈를 등록해주세요.",
        en: "Please register an issue.",
      },
      annotaionFixSuccess: {
        ko: "이슈 수정 완료",
        en: "Annotation Editing Completed",
      },
      nonAnnotation: {
        ko: "선택된 어노테이션이 없거나 통신 오류",
        en: "No selected annotation or server communication error",
      },
      projectLoading: {
        ko: "프로젝트 불러오는 중...",
        en: "Loading Project",
      },
      notMyProject: {
        ko: "소유하지 않은 프로젝트입니다.",
        en: "Not Permitted",
      },
      layerLoading: {
        ko: "레이어 정보 불러오는 중...",
        en: "Loading Layer",
      },
      creationAnnotation: {
        ko: "어노테이션 생성 완료",
        en: "Annotation Creation Completed",
      },
      failedCreationAnnotation: {
        ko: "어노테이션 생성 실패",
        en: "Annotation Creation Failed",
      },
      loadingFilterInfo: {
        ko: "필터링 정보 불러오는 중...",
        en: "Loading Filtering Information",
      },
      successDeleting: {
        ko: "삭제에 성공했습니다.",
        en: "Deletion was successful",
      },
      failedDeleting: {
        ko: "삭제가 실패했습니다.",
        en: "Deletion was unsuccessful",
      },
      loadingLongitudianlInfo: {
        ko: "단면 정보를 불러오는 중...",
        en: "Generating Elevation Profile",
      },
      loadingIssuelInfo: {
        ko: "이슈 정보를 불러오는 중...",
        en: "Loading Issue Information",
      },
      errorLoadLongitudinalInfo: {
        ko: "단면 정보를 불러오지 못했습니다.",
        en: "Elevation profile is not displayed",
      },
      loadingPage: {
        ko: "페이지 불러오는 중...",
        en: "Loading the Page",
      },
      loadingData: {
        ko: "데이터 불러오는 중...",
        en: "Loading the Data",
      },
      loadingAnnotation: {
        ko: "어노테이션 불러오는 중...",
        en: "Loading the Annotation",
      },
      noTargetForPosition: {
        ko: "좌표가 입력되지 않았습니다.",
        en: "Click on the map for getting position.",
      },
      loadingMeasurementInfo: {
        ko: "측정 정보를 불러오는 중...",
        en: "Loading measurement information.",
      },
      noMeasure: {
        ko: "측정 정보가 없습니다.",
        en: "No measurement information is available.",
      },
      noLength: {
        ko: "높이 정보가 없습니다.",
        en: "No height information is available.",
      },
      noDSM: {
        ko: "DSM 정보가 없습니다.",
        en: "No DSM information is available.",
      },
      noLayer: {
        ko: "레이어가 없습니다.",
        en: "No Layer is available.",
      },
      pleaseSelectACrossSection: {
        ko: "단면 선택",
        en: "select section",
      },
      noCrossSection: {
        ko: "대상 지역을 벗어났습니다.",
        en: "Out of the target area.",
      },
      validateStepDigit: {
        ko: "정수 2자리, 소수점 이하 1자리 이내로 입력해주세요.",
        en: "Please enter 2 integers and no more than 1 decimal place.",
      },
      validateStepLength: {
        ko: "각 선분의 길이보다 간격을 작게 입력해주세요.",
        en: "Please enter an interval smaller than the length of each line segment.",
      },
      longitudinalIntervalError: {
        ko: "점 간격을 정수 2자리, 소수점 이하 1자리 이내, 1m 이상 입력해주세요.",
        en: "Please enter a longitudinal cross-section interval of at least 1 m.",
      },
      longitudinalWidthError: {
        ko: "좌, 우 폭이 횡단 간격보다 커야 합니다.",
        en: "The left and right width must be greater than the crossing interval.",
      },
      startOverEnd: {
        ko: "시작일이 종료일보다 이후입니다.",
        en: "Start date is after end date.",
      },
      endBeforeStart: {
        ko: "종료일이 시작일보다 이전입니다.",
        en: "End date is before start date.",
      },
      createdOverStart: {
        ko: "시작일이 프로젝트 생성일보다 이전입니다.",
        en: "The start date is before the project creation date.",
      },
      loading3DTiles: {
        ko: "메쉬 모델 데이터 불러오는 중...",
        en: "Loading the mesh model...",
      },
      loading3DTilesError: {
        ko: "메쉬 모델 로드에 실패했습니다.",
        en: "Failed to load the mesh model.",
      },
    },
  },
  graph: {
    checkCrossSection: {
      ko: "단면 확인",
      en: "Check Cross Section",
    },
    checkLongitudeSection: {
      ko: "종단면 확인",
      en: "Check Elevation Profile",
    },
    height: {
      ko: "고도",
      en: "height",
    },
    distance: {
      ko: "거리",
      en: "distance",
    },
    line: {
      ko: "선형",
      en: "Linear",
    },
    alignment: {
      ko: "선형",
      en: "Alignment",
    },
    stratum: {
      ko: "지층",
      en: "stratum",
    },
    designHeight: {
      ko: "계획고",
      en: "design Height",
    },
    check: {
      ko: "확인",
      en: "Check",
    },
    longitudeSection: {
      ko: "종단면",
      en: "Elevation profile",
    },
    specificSection: {
      ko: "특정",
      en: "specific",
    },
    crossSection: {
      ko: "횡단면",
      en: "Cross Section",
    },
    crossSectionList: {
      ko: "횡단면 리스트",
      en: "Cross Section",
    },
    perpendicularInterval: {
      ko: "횡단 간격",
      en: "Cross Interval",
    },
    pointInterval: {
      ko: "점 간격",
      en: "point Interval",
    },
    pointSetting: {
      ko: "지점 설정",
      en: "Set up a point",
    },
    detailSetting: {
      ko: "세밀화 설정",
      en: "Detailed settings",
    },
    startPoint: {
      ko: "시작점",
      en: "start Point",
    },
    endPoint: {
      ko: "끝점",
      en: "end Point",
    },
    section: {
      ko: "구간",
      en: "section",
    },
    sectionStartPoint: {
      ko: "시점",
      en: "start Point",
    },
    sectionEndPoint: {
      ko: "종점",
      en: "end Point",
    },
    original: {
      ko: "원본 보기",
      en: "original",
    },
    detail: {
      ko: "세밀화 보기",
      en: "detailed",
    },
    startPointError: {
      ko: "시작 측점은 기본 값보다 작을 수 없습니다.",
      en: "The starting station cannot be smaller than the default value",
    },
    enterBranch: {
      ko: "지점을 입력해주세요.",
      en: "Please enter a branch",
    },
    validate: {
      dxfSaveFileDownloadError: {
        ko: "파일 다운로드 중 오류가 발생했습니다. 다시 시도해주세요.",
        en: "An error occurred while downloading the file. Please try again.",
      },
      dxfSaveFileGenerateNotice: {
        ko: "파일 생성 요청되었습니다. 보통 10분 이내 완료됩니다. 잠시 후에 확인 해주세요.",
        en: "You have been requested to create an file. It's usually done within 10 minutes. Please check it out in a moment.",
      },
      dxfSaveFileGenerateError: {
        ko: "파일 생성에 실패했습니다. 잠시 후 다시 시도해주세요.",
        en: "Failed to create file. Please try again in a moment.",
      },
      noSavedData: {
        ko: "저장된 데이터가 없습니다.",
        en: "No data has been saved.",
      },
      failDeleteData: {
        ko: "데이터를 삭제하는데 실패했습니다. 다시 시도해주세요.",
        en: "Failed to delete data. Please try again.",
      },
      savePerpendicular: {
        ko: "횡단면이 저장되었습니다.",
        en: "The cross-section has been saved.",
      },
      deletePerpendicular: {
        ko: "횡단면이 삭제되었습니다.",
        en: "The cross-section has been deleted.",
      },
      existingCrossSection: {
        ko: "이미 저장된 횡단면이 있습니다. 삭제 후 다시 시도해주세요.",
        en: "A cross-section has already been saved. Please delete it and try again.",
      },

      leftRightSpacing: {
        ko: "점 간격보다 좌,우 간격이 커야 합니다.",
        en: "The left and right spacing must be greater than the point spacing.",
      },
      endPointInfo: {
        ko: "종점을 입력하지 않거나 종점이 선형 길이보다 길다면 전체 횡단면을 볼 수 있습니다.",
        en: "If the end point is not specified or if it is longer than the linear length, the entire cross-section will be displayed.",
      },
      lngStartPoint: {
        ko: "종단면의 시작측점은 TEXT 파일에만 반영됩니다.",
        en: "The start point of the cross-section is only reflected in the TEXT file.",
      },
      startStepError: {
        ko: "시점은 기본 값보다 작을 수 없습니다.",
        en: "The starting station cannot be smaller than the default value",
      },
      endStepError: {
        ko: "종점은 시점보다 작을 수 없습니다.",
        en: "The end point cannot be smaller than the start point.",
      },
      sameStepError: {
        ko: "시점과 종점은 같을 수 없습니다.",
        en: "The start and end points cannot be the same.",
      },
      maxStratumFilesSelected: {
        ko: "파일은 최대 세 개까지만 선택할 수 있습니다.",
        en: "You can select up to three files only.",
      },
      maxStep: {
        ko: `간격은 ${CROSS_LONGITUDE_GRAPH.MAX_STEP} 이하이어야 합니다.`,
        en: `The value must be less than or equal to ${CROSS_LONGITUDE_GRAPH.MAX_STEP}.`,
      },
      increment: {
        ko: `간격은 ${CROSS_LONGITUDE_GRAPH.STEP_INCREMENT}의 배수이어야 합니다.`,
        en: `The value must be a multiple of ${CROSS_LONGITUDE_GRAPH.STEP_INCREMENT}`,
      },
      equal: {
        ko: "점 간격은 횡단 간격보다 같거나 작아야 합니다.",
        en: "The point interval must be equal to or less than the cross interval.",
      },
      setPoint: {
        ko: "시작점과 끝점을 설정해주세요",
        en: "Please set the starting point and the endpoint.",
      },
      pointAdditionError: {
        ko: "추가 지점은 시작측점보다 작을 수 없습니다.",
        en: "The additional point cannot be smaller than the starting point.",
      },
      pointAdded: {
        ko: "특정 횡단면이 추가되었습니다.",
        en: "A specific cross-section has been added.",
      },
      pointAdditionFailed: {
        ko: "특정 횡단면 추가에 실패했습니다. 잠시후 다시 시도해주세요.",
        en: "Failed to add a specific cross-section. Please try again later.",
      },
      pointAdditionTargetFailed: {
        ko: "이미 해당 거리에 횡단면이 있습니다. 입력한 거리를 확인해주세요.",
        en: "The cross section already exists on this street. Please check the specific street.",
      },
      distanceExceedsSegmentLength: {
        ko: "입력한 거리가 선분의 길이를 초과했습니다.",
        en: "The entered distance exceeds the length of the segment.",
      },
      crossSectionDeletionFailed: {
        ko: "횡단면 삭제에 실패했습니다. 잠시후 다시 시도해주세요.",
        en: "Failed to delete the cross-section. Please try again later.",
      },
    },
  },
  myPage: {
    accountInfo: {
      ko: "계정 정보",
      en: "Account Information",
    },
    quit: {
      ko: "회원탈퇴",
      en: "Membership Withdrawal",
    },
    quitAgreement: {
      ko: "회원 탈퇴 약관",
      en: " Membership Withdrawal Agreement",
    },
    quitAggrementDescription: {
      first: {
        ko: "이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면 지체없이 파기 합니다. 다만, 다른 법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 별도 분리 보관 후 파기합니다.",
        en: "The user’s personal information is promptly disposed of when the purpose of collecting and using the information is achieved. However, the personal information that must be retained under other laws is stored separately for the valid period and then disposed",
      },
      second: {
        ko: "   가. 파기절차 및 기한 수집·이용목적이 달성된 개인정보는 지체없이 파기되며, 관련 법령에 따라 보관되어야 할 경우 별도의 DB 에 옮겨져 내부 규정 및 관련 법령을 준수하여(개인정보 보유 및 이용기간) 일정기간동안 안전하게 보관된 후 지체없이 파기됩니다. 이때, DB 로 옮겨진 개인정보는 법률에 의한 경우를 제외하고 다른 목적으로 이용하지 않습니다.",
        en: "First, Disposal Procedure and Deadline: personal information collected and used for its intended purpose will be promptly disposed once its purpose is achieved. If, by law or internal regulations, it needs to be retained for a certain period, it will be securely stored in a separate database and then will be destroyed without delay after the specified certain period. Personal information transferred to the database will not be used for any other purposes, except as required by law",
      },
      third: {
        ko: " 나. 파기방법 전자적 파일 형태의 정보는 복구 및 재생할 수 없는 기술적 방법을 사용하여 완전하게 삭제합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.",
        en: "Second, Disposal Method: electronic information in file format will be completely deleted using technically irreversible methods to prevent recovery and regeneration. Personal information printed on paper will be destroyed through shredding or incineration",
      },
      fourth: {
        ko: "안내 사항을 모두 확인하였으며, 이에 동의합니다.",
        en: "I have reviewed all the instructions and hereby give my consent",
      },
    },
    msg: {
      atLeastOneSpecialCharacter: {
        ko: "비밀번호는 최소한 한개 이상의 특수문자를 포함해야 합니다.",
        en: "The password must contain at least one special character.",
      },
      atLeastOneDigit: {
        ko: "비밀번호는 최소한 한개 이상의 숫자를 포함해야 합니다.",
        en: "The password must contain at least one number.",
      },
      passwordLength: {
        ko: "비밀번호는 8글자 이상이어야 합니다.",
        en: "Password must be at least 8 characters long.",
      },
      atLeastOneLowerCaseLetter: {
        ko: "비밀번호는 최소한 한개 이상의 소문자를 포함해야 합니다.",
        en: "The password must contain at least one lowercase letter.",
      },
      passwordNeedCapital: {
        ko: "비밀번호는 최소한 한개 이상의 대문자를 포함해야 합니다.",
        en: "Password must contain at least one uppercase letter.",
      },
      onlyNumber: {
        ko: "숫자만 입력해주세요.",
        en: "Type only numbers",
      },
      notEnoughLength: {
        ko: "올바른 길이가 아닙니다.",
        en: "Wrong length of numbers.",
      },
      checkVerifyingEmail: {
        ko: "메일로 발송된 인증번호를 입력하세요.",
        en: "Please enter the authentication number sent by mail.",
      },
      makeSureQuiteQuestion: {
        ko: "회원 탈퇴 하시겠습니까?",
        en: "Would you like to withdraw your membership?",
      },
      makeSureQuitDoubleCheck: {
        ko: "회원 탈퇴를 원하신다면, '계정 삭제' 를 입력해 주세요.",
        en: "If you wish to withdraw your membership, please enter &quot;Account Deletion",
        checkWord: {
          ko: "계정 삭제",
          en: "Account Deletion",
        },
      },
      checkDuplicatedEmail: {
        ko: "이메일 중복을 확인해주세요.",
        en: "Please check the duplicated Email.",
      },
      idAllowEngNumber: {
        ko: "올바른 이메일 형식을 입력하세요.",
        en: "Enter the correct email format.",
      },
      emailAlreadyExists: {
        ko: "이미 존재하는 아이디입니다.",
        en: "Email already exists",
      },
      newtworkError: {
        ko: "네트워크 오류로 현재 중복 확인이 불가합니다.",
        en: "Duplicated verification is currently not possible due to a network error.",
      },

      usableEmail: {
        ko: "사용 가능한 이메일입니다.",
        en: "Available Email",
      },

      notMatchPassword: {
        ko: "비밀번호가 일치하지 않습니다.",
        en: "Passwords do not match.",
      },
      failedModifiedUser: {
        ko: "회원정보 수정에 실패했습니다.",
        en: "Failed to update member information",
      },
      warningTypicalform: {
        ko: "형식으로 입력해주세요",
        en: "Please enter the content with the specific format",
      },
      warningQuitDescription: {
        ko: "탈퇴 안내를 확인하고 동의해 주세요.",
        en: "Please confirm and agree to the withdrawal instructions",
      },
      joinCompleted: {
        ko: "회원가입이 완료되었습니다.",
        en: "Your registration is completed",
      },
      accountDeleted: {
        ko: "계정이 삭제되었습니다.",
        en: "Your account has been deleted",
      },
      failedDeletingAcount: {
        ko: " 계정 삭제에 실패했습니다. 관리자에게 문의해주세요.",
        en: "Account deletion failed. Please contact the administrator",
      },
    },
  },
  userManagement: {
    manageProjectPermission: {
      ko: "프로젝트 권한 관리",
      en: "Manage Project Permission",
    },
    addUser: {
      ko: "사용자 추가",
      en: "Add a user",
    },
    delete: {
      ko: "삭제",
      en: "Delete",
    },
    joinStatus: {
      ko: "가입 상태",
      en: "Join Status",
    },
    joined: {
      ko: "가입",
      en: "joined",
    },
    unjoined: {
      ko: "미가입",
      en: "unjoined",
    },
    msg: {
      userAdding: {
        ko: "변경사항 반영 중입니다.",
        en: "Proccessing...",
      },
      userAdded: {
        ko: "사용자가 추가되었습니다.",
        en: "The user was added",
      },
      userDeleted: {
        ko: "사용자가 삭제되었습니다.",
        en: "The user was deleted",
      },
      userEdited: {
        ko: "사용자 권한이 수정되었습니다.",
        en: "User permissions have been modified.",
      },
      confirmUserDelete: {
        ko: "사용자를 프로젝트에서 삭제하시겠습니까?",
        en: "Are you sure you want to delete the user from the project?",
      },
      errorUserEdit: {
        ko: "네트워크 오류로 현재 수정이 불가합니다.",
        en: "To modify the role is currently not possible due to a network error.",
      },
      errorUserDelete: {
        ko: "네트워크 오류로 현재 삭제가 불가합니다.",
        en: "To delete the user is currently not possible due to a network error.",
      },
      projectFirst: {
        ko: "프로젝트를 먼저 생성해주세요.",
        en: "Please create a project first",
      },
      projectsListing: {
        ko: "프로젝트 리스트 불러오는 중.",
        en: "Loading...",
      },
    },
  },
  header: {
    service: {
      ko: "서비스",
      en: "Service",
    },
    platform: {
      ko: "플랫폼",
      en: "Platform",
    },
    survey: {
      ko: "드론 측량 / 공간데이터 가공",
      en: "Survey",
    },
    plan: {
      ko: "이용플랜",
      en: "Plan",
    },
    contact: {
      ko: "1:1 문의",
      en: "Contact",
    },
    manual: {
      ko: "메뉴얼",
      en: "Manual",
    },
    project: {
      ko: "프로젝트",
      en: "Project",
    },
    myPage: {
      ko: "마이페이지",
      en: "MyPage",
    },
    auth: {
      login: {
        ko: "로그인",
        en: "Sign In",
      },
      logging: {
        ko: "로그인 중...",
        en: "Logging in...",
      },
      logout: {
        ko: "로그아웃",
        en: "Sign Out",
      },
      join: {
        ko: "회원가입",
        en: "Sign Up",
      },
      sendingcode: {
        ko: "인증 번호 전송중...",
        en: "Sending code..",
      },
      changepw: {
        ko: "비밀번호 변경",
        en: "Change Password",
      },
      changingpw: {
        ko: "비밀번호 변경중...",
        en: "Changing Password...",
      },
      verifyemail: {
        ko: "이메일 인증하기",
        en: "Verify Email",
      },
    },
  },
  sideNav: {
    error: {
      nonProject: {
        ko: "프로젝트 하나를 선택해 주세요.",
        en: "Please select a project",
      },
    },
  },

  constants: {
    imageupload: {
      ko: "이미지 업로드",
      en: "Upload image",
    },
    checkall: {
      ko: "전체 선택",
      en: "Check All",
    },
    distance: {
      ko: "거리(m)",
      en: "Distance",
    },
    height: {
      ko: "고도(m)",
      en: "Elevation",
    },
    pointBim: {
      ko: "포인트 빔",
      en: "Point Cloud",
    },

    image: {
      ko: "이미지",
      en: "Image",
    },
    optimizedImage: {
      ko: "(최적화/원시)",
      en: "(Optimized/Total)",
    },
    video: {
      ko: "비디오",
      en: "Video",
    },
    document: {
      ko: "문서",
      en: "Document",
    },
    layer: {
      ko: "레이어",
      en: "Layer",
    },
    noLayer: {
      ko: "선택 가능한 레이어가 없습니다.",
      en: "No selectable layers available.",
    },
    layerSelect: {
      ko: "레이어를 선택 해주세요.",
      en: "Please choose a layer.",
    },
    projectName: {
      ko: "프로젝트명",
      en: "Name",
    },
    project: {
      ko: "프로젝트",
      en: "Project",
    },
    address: {
      ko: "주소",
      en: "Address",
    },
    position: {
      ko: "좌표(위경도)",
      en: "Coordinate(Lat-Lng)",
    },
    infraType: {
      ko: "인프라 타입",
      en: "Infrastructure",
    },
    goBack: {
      ko: "뒤로가기",
      en: "Go Back",
    },
    opacity: {
      ko: "투명도",
      en: "Transparency",
    },
    loadingCalInfo: {
      ko: "측정 정보 불러오는 중...",
      en: "Loading Measurement Information",
    },
    searchAddress: {
      ko: "주소 검색",
      en: "Search Address",
    },
    requiredValue: {
      ko: "필수 값입니다.",
      en: "This field is mandatory",
    },
    handsOnWriting: {
      ko: "직접 입력",
      en: "Enter manually",
    },
    createdDate: {
      ko: "생성날짜",
      en: "Creation",
    },
    updatedDate: {
      ko: "수정날짜",
      en: "Modification",
    },
    completedDate: {
      ko: "완료일",
      en: "Completion Date",
    },
    completed: { ko: "완료", en: "Completed" },
    objectType: {
      ko: "객체 타입",
      en: "Object",
    },
    memo: {
      ko: "메모",
      en: "Note",
    },
    cancel: {
      ko: "취소",
      en: "Cancel",
    },
    save: {
      ko: "저장",
      en: "Save",
    },
    makeIssueTitle: {
      ko: "이슈 제목을 입력하세요.",
      en: "Please enter an issue title.",
    },
    modify: {
      ko: "수정",
      en: "Edit",
    },
    other: {
      ko: "기타",
      en: "Others",
    },
    deleting: {
      ko: "삭제",
      en: "Deletion",
    },
    delete: {
      ko: "삭제하기",
      en: "Delete",
    },
    search: {
      ko: "검색",
      en: "Search",
    },
    add: {
      ko: "추가",
      en: "Add",
    },
    remove: {
      ko: "제거",
      en: "Removal",
    },
    issueList: { ko: "이슈 사항 목록", en: "Issue List" },
    type: { ko: "타입", en: "Type" },
    beforeProcess: {
      ko: "처리 전",
      en: "Before Processing",
    },
    processing: {
      ko: "처리 중",
      en: "Processing",
    },
    processCompleted: {
      ko: "처리 완료",
      en: "Processed",
    },
    processFailed: {
      ko: "처리 실패",
      en: "Processing Failure",
    },
    twoDimensionImage: {
      ko: "2D 이미지",
      en: "2D Image",
    },
    threeDimensionImage: {
      ko: "3D 이미지",
      en: "3D Image",
    },
    requestedProcess: {
      ko: "처리 요청",
      en: "Processing Request",
    },
    checkingRequest: {
      ko: "검수 중",
      en: "Under Review",
    },
    coordinateSystem: {
      ko: "출력 좌표계",
      en: "Output Coordinate System",
    },
    resolution: {
      ko: "해상도(GSD)",
      en: "Ground Sample Distance(GSD)",
    },
    download: {
      ko: "다운로드",
      en: "Download",
    },
    update: {
      ko: "업데이트",
      en: "Update",
    },
    downing: {
      ko: "다운 중",
      en: "Downing",
    },
    checking: {
      ko: "확인 중",
      en: "Checking",
    },
    createFile: {
      ko: "파일 생성",
      en: "Create a file",
    },
    creating: {
      ko: "생성 중",
      en: "Creating",
    },
    group: {
      ko: "그룹",
      en: "Group",
    },
    preview: {
      ko: "미리보기",
      en: "Preview",
    },
    none: {
      ko: "없음",
      en: "None",
    },
    edit: {
      ko: "편집",
      en: "Edit",
    },
    orthomosaicImage: {
      ko: "정사영상",
      en: "Orthomosaic Image",
    },
    gisLayer: {
      ko: "GIS 레이어",
      en: "GIS Layer",
    },
    subLayer: {
      ko: "보조 레이어",
      en: "Secondary Layer",
    },
    annotation: {
      ko: "어노테이션",
      en: "Annotation",
    },
    latitude: {
      ko: "위도",
      en: "Latitude",
    },
    latitudeShorten: {
      ko: "위도",
      en: "Lat",
    },
    longitude: {
      ko: "경도",
      en: "Longitude",
    },
    longitudeShorten: {
      ko: "경도",
      en: "Lng",
    },
    location: {
      ko: "위치",
      en: "Position",
    },
    create: {
      ko: "생성",
      en: "Creation",
    },
    annotationType: {
      ko: "어노테이션 타입",
      en: "Type",
    },
    issueDescription: {
      ko: "이슈 사항",
      en: "Anomaly List",
    },
    issueDegree: {
      ko: "이슈 정도",
      en: "Category",
    },
    issueGrade: {
      ko: "이슈 단계",
      en: "Quantity Level",
    },
    createIssue: {
      ko: "이슈 생성",
      en: "Creation",
    },
    date: {
      ko: "날짜",
      en: "Date",
    },
    reset: {
      ko: "초기화",
      en: "Reset",
    },
    check: {
      ko: "확인",
      en: "Confirmation",
    },
    filtering: {
      ko: "필터링",
      en: "Filtering",
    },
    totalIssue: {
      ko: "총이슈",
      en: "Total issue",
    },
    cases: {
      ko: " 건",
      en: " cases",
    },
    measurement: {
      ko: "측정",
      en: "Measurement",
    },
    information: {
      ko: " 정보",
      en: " Information",
    },
    coordinates: {
      ko: "좌표",
      en: "Coordinates",
    },
    area: {
      ko: "면적",
      en: "Area",
    },
    surfaceArea: {
      ko: "표면적",
      en: "Surface Area",
    },
    line: {
      ko: "선분",
      en: "Line",
    },
    length: {
      ko: " 길이",
      en: " Length",
    },
    elevationDifference: {
      ko: "고도 차",
      en: "Elevation difference",
    },
    slope: {
      ko: "경사",
      en: "Slope",
    },
    volume: {
      ko: "체적",
      en: "Volume",
    },
    setFloorHeight: {
      ko: "바닥면 높이(m)",
      en: "floor height(m)",
    },
    // 글자가 길어 아래와 같이 번역
    volumeCalculate: {
      ko: "체적 계산",
      en: "Calculate",
    },
    crossSection: {
      ko: "단면",
      en: "Cross section",
    },
    startingSidePoint: {
      ko: "시작 측점",
      en: "Starting side point",
    },
    setSpacing: {
      ko: "간격 설정",
      en: "Set spacing",
    },
    checkTheCrossSection: {
      ko: "단면 확인",
      en: "Check Elevation Profile",
    },
    cutVolume: {
      ko: "CUT(절토량)",
      en: "Cut Volume",
    },
    fillVolume: {
      ko: "FILL(성토량)",
      en: "Fill Volume",
    },
    netVolume: {
      ko: "순체적",
      en: "Net Volume",
    },
    settingInterval: {
      ko: "단면 간격(m)",
      en: "Setting the interval(m)",
    },
    startingSurveyPoint: {
      ko: "시작 측점(m)",
      en: "Starting survey point(m)",
    },
    checkCrossSection: {
      ko: "단면 확인",
      en: "Check Elevation Profile",
    },

    longitudeGraph: {
      ko: "고도 프로파일",
      en: "height Profile",
    },
    longitudeSliderNotice: {
      ko: "오른쪽 슬라이더를 사용해 고도 프로파일의 급격한 변화 지점을 중심으로 데이터량을 조절할 수 있습니다.",
      en: "You can use the right slider to adjust the amount of data around the point of sudden change in the elevation profile.",
    },
    longitudeDetailNotice: {
      ko: "그래프에서 자세하게 보고 싶은 부분의 점 간격을 입력하고 시작점과 끝점을 선택해주세요.",
      en: "Please enter the point interval you wish to see in detail on the graph, and select the start and end points.",
    },
    perpendicular: {
      ko: "종횡단면",
      en: "cross section",
    },
    perpendicularGraph: {
      ko: "횡단면 고도 프로파일",
      en: "cross height Profile",
    },
    perpendicularWidth: {
      ko: "폭 설정",
      en: "Width setting",
    },
    left: {
      ko: "좌",
      en: "left",
    },
    right: {
      ko: "우",
      en: "right",
    },
    perpendicularInterval: {
      ko: "횡단 간격",
      en: "crossing interval",
    },
    longitudeGraphPart: {
      ko: "부분 보기",
      en: "partial view",
    },
    longitudeGraphTotal: {
      ko: "전체 보기",
      en: "full view",
    },
    total2DLength: {
      ko: "총 2D 길이",
      en: "total 2D Length",
    },
    total3DLength: {
      ko: "총 3D 길이",
      en: "total 3D Length",
    },
    upload: {
      ko: "업로드",
      en: "Upload",
    },
    totalAmount: {
      ko: "총 용량",
      en: "Total Data Size",
    },
    writer: {
      ko: "작성자",
      en: "Author",
    },
    organization: {
      ko: "소속",
      en: "Affiliation",
    },
    title: { ko: "제목", en: "Title" },
    rank: {
      ko: "직급",
      en: "Position",
    },
    email: {
      ko: "이메일",
      en: "Email",
    },
    permission: {
      ko: "권한",
      en: "Permission",
    },
    createReport: {
      ko: "리포트 작성",
      en: "Auto-generated Report(pdf)",
    },
    id: {
      ko: "아이디",
      en: "ID",
    },
    username: {
      ko: "이름",
      en: "username",
    },
    no: {
      ko: "번호",
      en: "No",
    },
    phone: {
      ko: "휴대폰 번호",
      en: "Phone",
    },
    major: {
      ko: "분야",
      en: "Field",
    },
    job: {
      ko: "직무",
      en: "Role",
    },
    mypage: {
      ko: "마이페이지",
      en: "MyPage",
    },
    heightProfile: {
      ko: "고도 프로파일",
      en: "Height Profile",
    },
    imageList: {
      ko: "이미지 리스트",
      en: "Image List",
    },
    total: {
      ko: "총",
      en: "Total",
    },
    imagesCounting: {
      ko: "개의 이미지",
      en: "Images",
    },
    noneImages: {
      ko: "이미지가 없습니다.",
      en: "No Image",
    },
    requestMapping: {
      ko: "요청하기",
      en: "Request",
    },
    requestCompleted: {
      ko: "요청완료",
      en: "Done",
    },
    password: {
      ko: "비밀번호",
      en: "password",
    },

    doubleCheck: {
      ko: "비밀번호 확인",
      en: "Verify Password",
    },
    linkPositions: {
      ko: "위치 연계",
      en: "Link Position 2D & 3D",
    },
    idDoubleCheck: {
      ko: "중복확인",
      en: "Double Check",
    },
    user: {
      ko: "사용자",
      en: "User",
    },
    submit: {
      ko: "확인",
      en: "Submit",
    },
    resend: {
      ko: "재전송",
      en: "Resend",
    },
    next: {
      ko: "다음",
      en: "Next",
    },
    code: {
      ko: "인증번호",
      en: "Code Number",
    },
  },
  pdf: {
    author: {
      ko: "작 성 자",
      en: "Author",
    },
    organization: {
      ko: "소 속",
      en: "Affiliation",
    },
    rank: {
      ko: "직 무",
      en: "Role",
    },
    email: {
      ko: "이 메 일",
      en: "Email",
    },
    report: {
      ko: " 리포트",
      en: " Report",
    },
    groupAssociations: {
      ko: "그룹 연계",
      en: "Group associations",
    },
    issue: {
      ko: "이슈",
      en: "Issue",
    },
    issueName: {
      ko: "이슈명",
      en: "Issue Name",
    },
    count: {
      ko: "개",
      en: "",
    },
    layout: {
      ko: "배치도",
      en: "Layout",
    },
    summary: {
      ko: "요약",
      en: "Summary",
    },
    issueLevel: {
      ko: "이슈정도",
      en: "Level",
    },
    issueType: {
      ko: "이슈타입",
      en: "Type",
    },
    issuePriority: {
      ko: "우선순위",
      en: "Priority",
    },
    comment: {
      ko: "의견",
      en: "Comment",
    },
    acquired: {
      ko: "데이터 취득날짜",
      en: "Acquired",
    },
    msg: {
      loadingPdf: {
        ko: "pdf를 불러오는 중...",
        en: "Loading PDF...",
      },
    },
  },
  pricing: {
    standard1: {
      ko: "월 구독 방식",
      en: "Billed monthly",
    },
    standard1_2: {
      ko: "(이미지 중복도 80% 이상 유지)",
      en: "(Maintain at least 80% image redundancy)",
    },
    standard2: {
      ko: "드론 이미지 3000장 / 드론프로젝트 구축",
      en: "3000 image upload per map ",
    },
    standard3: {
      ko: "월 2회 데이터 처리",
      en: "2 times image mapping a month",
    },
    standard4: {
      ko: "이미지 매핑 결과물 다운로드",
      en: "Downloading Image Mapping Results",
    },
    advanced1: {
      ko: "년 구독 방식으로 각종 사용 옵션을 유연하게 협의",
      en: "Flexible negotiation of service options",
    },
    advanced1_2: {
      ko: "",
      en: "through an annual subscription plan",
    },
    contactUs: {
      ko: "문의하기",
      en: "Contact us",
    },
    title: {
      ko: "드론스퀘어 서비스 플랜",
      en: "DroneSquare Service Plans",
    },
    description1: {
      ko: "드론스퀘어가 제공하는 정확한 데이터 분석으로 현장을 모니터링하고",
      en: "Monitor your sites with accurate data analytics provided by Dronesquare.",
    },
    description2: {
      ko: "최적의 유지보수를 실현하세요.",
      en: "Realize optimal maintenance.",
    },
    standard_subtitle: {
      ko: "소수의 대상 객체(현장)를 분석하고 관리하고자 하는 경우",
      en: "managing a small number of target objects/sites",
    },
    advanced_subtitle: {
      ko: "다수의 대상 객체(현장)을 분석하고 관리하고자 하는 경우",
      en: "managing a large number of target objects/sites",
    },
  },
};

export const noticeLocale = {
  title: {
    ko: "시스템 점검 공지 안내",
    en: "System Maintenance",
  },
  content: {
    ko: [
      {
        type: "text",
        text: "보다 안정적인 서비스를 제공하기 위해",
        lineEnd: true,
      },
      {
        type: "text",
        text: "DB 서버 점검을 수행하고 있습니다.",
        lineEnd: true,
      },
      {
        type: "text",
        text: "점검 일정: 매일 오후 10시 ~ 오전 7시",
        lineEnd: true,
      },
    ],
    en: [
      {
        type: "text",
        text: "We are performing DB server maintenance",
        lineEnd: true,
      },
      { type: "text", text: "to provide stable service.", lineEnd: true },
      {
        type: "text",
        text: "Maintenance schedule: 10 pm to 7 am daily",
        lineEnd: false,
      },
    ],
  },
};

export const errorNotice = {
  title: {
    ko: "시스템 점검",
    en: "Emergency Failure Response",
  },
  content: {
    ko: [
      {
        type: "text",
        text: "시스템 점검중입니다.",
        lineEnd: true,
      },
      {
        type: "text",
        text: "조속하게 처리하겠습니다.",
        lineEnd: true,
      },
    ],
    en: [
      {
        type: "text",
        text: "There is a system failure and we are fixing it.",
        lineEnd: true,
      },
      {
        type: "text",
        text: "We will handle it as soon as possible.",
        lineEnd: true,
      },
      {
        type: "text",
        text: "Thank you.",
        lineEnd: false,
      },
    ],
  },
};

export const newYearLocale = {
  title: {
    ko: "설 연휴 점검 안내",
    en: "NewYear Maintenance",
  },
  content: {
    ko: [
      {
        type: "text",
        text: "설 연휴 기간 동안 잠시 서비스 이용이",
        lineEnd: true,
      },
      {
        type: "text",
        text: "중단될 예정입니다. 양해 부탁드립니다.",
        lineEnd: true,
      },
      {
        type: "text",
        text: "점검 일정: 2024년 2월 9일(금) ~ 2월 12일(월)",
        lineEnd: true,
      },
    ],
    en: [
      {
        type: "text",
        text: "We'll be taking a short break over",
        lineEnd: true,
      },
      {
        type: "text",
        text: "the Lunar New Year holiday.",
        lineEnd: true,
      },
      {
        type: "text",
        text: "Thank you for your understanding.",
        lineEnd: true,
      },
      {
        type: "text",
        text: "Maintenance schedule: February 9 ~ 12, 2024",
        lineEnd: false,
      },
    ],
  },
};

export const doNotShowAgainButton = (days: number) => ({
  ko: `${days}일간 보지 않기`,
  en: `Do not show for ${days} days`,
});

export const manualLocale = {
  title: {
    project: {
      ko: "프로젝트",
      en: "Project",
    },
    orthomosaic: {
      ko: "정사영상",
      en: "Ortho-image",
    },
    threemodel: {
      ko: "3D모델",
      en: "3D Model",
    },
    multiview: {
      ko: "다중뷰",
      en: "Multi-view",
    },
  },
  projectSection: {
    projectManagement: {
      title: {
        ko: [
          { type: "text", text: "프로젝트 관리", lineEnd: true },
          { type: "text", text: "어플리케이션", lineEnd: false },
        ],
        en: [
          { type: "text", text: "Project", lineEnd: true },
          { type: "text", text: "Management", lineEnd: true },
          { type: "text", text: "Application", lineEnd: false },
        ],
      },
    },
    projectManagementDescription: {
      ko: [
        { type: "text", text: "대용량 드론 이미지 데이터와", lineEnd: true },
        { type: "text", text: "이미지 매핑 결과물을", lineEnd: true },
        {
          type: "text",
          text: "프로젝트 단위로 플랫폼에 구축하고",
          lineEnd: true,
        },
        {
          type: "text",
          text: "그룹으로 관리할 수 있는",
          lineEnd: true,
        },
        {
          type: "text",
          text: "프로젝트 관리 도구를 제공합니다.",
          lineEnd: false,
        },
      ],
      en: [
        {
          type: "text",
          text: "Provides project management",
          lineEnd: true,
        },
        {
          type: "text",
          text: "tools to build large amounts of",
          lineEnd: true,
        },
        {
          type: "text",
          text: "drone image data and image mapping",
          lineEnd: true,
        },

        {
          type: "text",
          text: "output onto the platform by project",
          lineEnd: true,
        },
        {
          type: "text",
          text: "and manage those projects in a group.",
          lineEnd: true,
        },
      ],
    },
  },
  projectContent: {
    title: {
      ko: "프로젝트",
      en: "Project",
    },
    createProject: {
      title: {
        ko: "1. 프로젝트 생성",
        en: "1. Creating a project",
      },
      createProjectDescription: {
        ko: [
          {
            type: "text",
            text: "새 프로젝트를 만들려면 다음과 같이 하세요.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "프로젝트 목록 섹션으로 이동해",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "새 프로젝트",
            lineEnd: false,
          },
          {
            type: "text",
            text: "를 클릭합니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "검색창에 대상 지역의 주소를 입력하거나 지도 위에서 마커를 클릭하여 지정합니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "프로젝트 이름, 객체 유형, 메모를 입력하고 프로젝트의 인프라 유형을 선택합니다.",
            lineEnd: true,
          },
          {
            type: "button",
            text: "저장",
            lineEnd: false,
          },
          { type: "text", text: "을 클릭합니다.", lineEnd: false },
        ],
        en: [
          {
            type: "text",
            text: "To create a new project,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Navigate to the section of Project List, click",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "New Project.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Enter the address of target area in the search bar or specify it on the map",
            lineEnd: true,
          },
          {
            type: "text",
            text: "by clicking.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Type  a project name, object type and note, and select infrastructure type",
            lineEnd: true,
          },
          {
            type: "text",
            text: "for the project.",
            lineEnd: true,
          },
          { type: "text", text: "Click ", lineEnd: false },
          {
            type: "button",
            text: "Save.",
            lineEnd: false,
          },
        ],
      },
    },
    modifyProject: {
      title: {
        ko: "2. 프로젝트 수정/삭제",
        en: "2. Editing a project information",
      },
      modifyProjectDescription: {
        ko: [
          {
            type: "text",
            text: "기존 프로젝트 정보(이름, 주소, 개체 유형 등)를 편집하려면 다음과 같이 하세요.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "사이드바에서 섹션 하단의 ",
            lineEnd: false,
          },
          {
            type: "button",
            text: "편집",
            lineEnd: false,
          },
          {
            type: "text",
            text: "을 클릭합니다.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "새 프로젝트 창",
            lineEnd: false,
          },
          {
            type: "text",
            text: "이 열리고 프로젝트 속성을 편집할 수 있습니다.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "프로젝트 정보 ",
            lineEnd: false,
          },
          {
            type: "text",
            text: "섹션에 대상 영역의 썸네일 보기가 표시됩니다.",
            lineEnd: false,
          },
        ],
        en: [
          {
            type: "text",
            text: "To edit the existing project information(name, address, object type etc.)",
            lineEnd: true,
          },
          {
            type: "text",
            text: "On the side bar, click ",
            lineEnd: false,
          },
          {
            type: "button",
            text: "Edit",
            lineEnd: false,
          },
          {
            type: "text",
            text: "at the bottom of the section.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "The window of",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "New Project ",
            lineEnd: false,
          },
          {
            type: "text",
            text: "opens and enables us to edit project property.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "In the section of",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Project Information,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "the thumbnail view of target area is displayed.",
            lineEnd: true,
          },
        ],
      },
    },
    manageDroneImage: {
      title: {
        ko: "3. 드론 이미지",
        en: "3. UAV image",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "UAV 이미지를 업로드하려면 다음과 같이 하세요.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "사이드바에서",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "UAV 이미지 관리 > 이미지 추가",
            lineEnd: false,
          },
          {
            type: "text",
            text: "를 클릭합니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "십자 아이콘을 클릭하여 이미지를 추가하면 이미지가 저장된 폴더로 이동합니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "드론스퀘어에 추가할 이미지를 선택한 후",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "열기",
            lineEnd: false,
          },
          {
            type: "text",
            text: "를 클릭합니다.",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "참고: 이미지는 *.jpg, *.jpeg로 추가할 수 있습니다.",
            lineEnd: true,
          },
          {
            type: "button",
            text: "이미지 업로드",
            lineEnd: false,
          },
          {
            type: "text",
            text: " 를 클릭하면 이미지 업로드가 완료됩니다.",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "참고: 업로드 진행률이 100%에 도달하기 전에 종료하면, 업로드가 중단됩니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "섹션 상단의",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "이미지",
            lineEnd: false,
          },
          {
            type: "text",
            text: "를 클릭하여 업로드된 이미지를 확인합니다. ",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "참고: 업로드한 파일이 보이지 않으면 새로고침 버튼을 눌러주세요.",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "이미지 목록에서 이미지를 선택하여 이미지를 제거할 수 있습니다.",
            lineEnd: true,
          },
        ],
        en: [
          {
            type: "text",
            text: "To upload UAV images,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "On the side bar, click",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "UAV Image Management > Add Images",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Click cross icon to add the images.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Navigate to select the folder where the images are stored,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "select images to be added to DroneSquare, and click ",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Open.",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "Note: Images can be added as *.jpg, *.jpeg.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Click ",
            lineEnd: false,
          },
          {
            type: "button",
            text: "Upload Image",
            lineEnd: false,
          },
          {
            type: "text",
            text: " to complete image uploading process.",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "Note: If you exit before the upload progress reaches 100%,",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "the upload will be interrupted.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Click ",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Images",
            lineEnd: false,
          },
          {
            type: "text",
            text: " at the upper of the section to check images uploaded.",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "Note: if you don't see the uploaded file, please press the refresh button.",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "It is possible to remove images by selecting them in the image list.",
            lineEnd: true,
          },
        ],
      },
    },
    droneImageMapping: {
      title: {
        ko: "4. 드론 이미지 맵핑",
        en: "4. Drone Image Mapping",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "이미지 매핑 결과(정사영상, 포인트 클라우드, 메쉬, DSM)를 생성하려면,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "사이드 바에서 ",
            lineEnd: false,
          },
          {
            type: "button",
            text: "맵핑 요청",
            lineEnd: false,
          },
          {
            type: "text",
            text: "을 클릭합니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "이미지 매핑 프로세스에 지상 제어점(GCP)을 추가하여 프로세스에 추가하여",
            lineEnd: true,
          },
          {
            type: "text",
            text: "프로젝트의 위치 정확도를 향상시킬 수 있습니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "이를 위해 GCP 정보가 포함된 파일을 이메일로 보내주세요.",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "참고: 데이터 처리에는 Pix4D 또는 메타쉐이프를 기반으로 1~2일이 소요됩니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "데이터 처리가 완료되면 좌표와 GSD를 포함한 요약본이 제공되며",
            lineEnd: true,
          },
          {
            type: "text",
            text: "이미지 매핑 결과를 다운로드할 수 있습니다.",
            lineEnd: true,
          },
        ],
        en: [
          {
            type: "text",
            text: "To generate image mapping results(orthomosaic image, point cloud,",
            lineEnd: true,
          },
          {
            type: "text",
            text: " mesh, DSM).",
            lineEnd: true,
          },
          {
            type: "text",
            text: "On the side bar, click ",
            lineEnd: false,
          },
          {
            type: "button",
            text: "Request",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Ground Control Points(GCPs) can be added to the image mapping process ",
            lineEnd: true,
          },
          {
            type: "text",
            text: "to improve the positional accuracy of the project.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "For this, please send the file with GCPs’ information by email.",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "Note: The data process is expected to take 1 to 2 days based on Pix4D or",
            lineEnd: true,
          },
          {
            type: "color",
            color: "red",
            text: "Metashape. When the data process is done, its summary including coordinate and GSD is simply provieded and image mapping results will be ready to be downloaded.",
            lineEnd: true,
          },
        ],
      },
    },
    manageProjectGroup: {
      title: {
        ko: "5. 프로젝트 그룹 관리",
        en: "5. Project Group Management",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "프로젝트 그룹을 만들려면 사이드바에서",
            lineEnd: false,
          },
          {
            type: "link",
            link: "#manual-multiView-section",
            text: " 프로젝트 그룹 관리",
            lineEnd: false,
          },
          {
            type: "text",
            text: "를 클릭합니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "첫번째, 새 그룹의 이름을 입력하고",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "추가 버튼",
            lineEnd: false,
          },
          {
            type: "text",
            text: "을 클릭합니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "두번째, 새 그룹을 선택하고",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "프로젝트 추가",
            lineEnd: false,
          },
          {
            type: "text",
            text: "를 클릭합니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "마지막으로",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "프로젝트 리스트 팝업",
            lineEnd: false,
          },
          {
            type: "text",
            text: "에서 같은 그룹에 속할 프로젝트를 선택하면",
            lineEnd: true,
          },
          {
            type: "text",
            text: "대상 지역의 시계열적 점검 목적에 따라 프로젝트를 그룹화할 수 있습니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "그룹화된 프로젝트는",
            lineEnd: false,
          },
          {
            type: "link",
            link: "#manual-multiView-section",
            text: " 2D&3D 기반 검사 어플리케이션 - 다중뷰",
            lineEnd: false,
          },
          {
            type: "text",
            text: "에서",
            lineEnd: true,
          },
          {
            type: "text",
            text: "접근/관리/가시화가 가능합니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "또한 동일한 현장/시설물에서 반복적인 시계열적 점검/관리(시공관리, 변화탐지,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "이상 유무 등)을 효율적으로 수행할 수 있습니다.",
            lineEnd: true,
          },
        ],
        en: [
          { type: "text", text: "To create project groups,", lineEnd: true },
          { type: "text", text: "On the side bar, click ", lineEnd: false },
          {
            type: "link",
            link: "#manual-multiView-section",
            text: "Project Group Management",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Type a name for the new group and click",
            lineEnd: false,
          },
          { type: "bold", text: "Add ", lineEnd: false },
          { type: "text", text: "button.", lineEnd: true },
          {
            type: "text",
            text: "Select the new group and click",
            lineEnd: false,
          },
          { type: "bold", text: "Add Project.", lineEnd: true },
          { type: "text", text: "On the", lineEnd: false },
          { type: "bold", text: "Project Lists", lineEnd: false },
          {
            type: "text",
            text: " pop-up, choose a project to be in the same group.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Projects can be grouped based on the time-series inspection needs of the target area.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "The projects grouped can be accessed in the Multi-View window of 2D&3D based inspection application, so that you efficiently perform repeated ",
            lineEnd: true,
          },
          {
            type: "text",
            text: "time series checks/management(construction management, change detection, abnormalities, etc.) on the same site/facility.",
            lineEnd: true,
          },
        ],
      },
    },
    manageEtcData: {
      title: { ko: "6. 기타 데이터 관리", en: "6. Additional Data Management" },
      description: {
        ko: [
          {
            type: "text",
            text: "다양한 프로젝트 관련 데이터를 관리하려면,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "사이드바에서 기타 데이터 관리를 클릭합니다.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "참고 데이터",
            lineEnd: false,
          },
          {
            type: "text",
            text: "에는 이미지, 동영상, 문서를 추가할 수 있는 세 가지 섹션이 있습니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "이미지와 비디오는 플랫폼에서 바로 시각화되어 발주처 및 주민 설명회에서",
            lineEnd: true,
          },
          {
            type: "text",
            text: "유용하게 사용이 가능합니다.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "중첩 데이터",
            lineEnd: false,
          },

          {
            type: "text",
            text: "에서는 GIS 레이어를 추가할 수 있습니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "업로드한 GIS 레이어(설계도, 지적도 등)는 정사영상 상에서 중첩시킬 수 있습니다.",
            lineEnd: true,
          },
        ],
        en: [
          {
            type: "text",
            text: "To manage various project-related data,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "On the side bar, click",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Additional Data Management",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "In Miscellaneous Files,",
            lineEnd: false,
          },
          {
            type: "text",
            text: "there are three sections for adding images, videos, and documents.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Images and videos are visualized right on the platform.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "In Overlay Layers,",
            lineEnd: false,
          },
          {
            type: "text",
            text: "GIS layers are to be added",
            lineEnd: true,
          },
          {
            type: "text",
            text: "GIS layers(design plans, cadastral maps etc.) can be overlayed on",
            lineEnd: true,
          },
          {
            type: "text",
            text: "an ortho-image map in the application.",
            lineEnd: true,
          },
        ],
      },
    },
  },
  inspection2DSection: {
    title: {
      ko: [
        {
          type: "text",
          text: "드론 고해상도 이미지",
          lineEnd: true,
        },
        {
          type: "text",
          text: "/ 정사영상 기반",
          lineEnd: true,
        },
        {
          type: "text",
          text: "점검 어플리케이션",
          lineEnd: false,
        },
      ],
      en: [
        {
          type: "text",
          text: "Drone HD Image",
          lineEnd: true,
        },
        {
          type: "text",
          text: "/ ortho-image",
          lineEnd: true,
        },
        {
          type: "text",
          text: "based inspection",
          lineEnd: true,
        },
        {
          type: "text",
          text: "application",
          lineEnd: false,
        },
      ],
    },
    description: {
      ko: [
        {
          type: "text",
          text: "정사영상에서 인프라에 대한",
          lineEnd: true,
        },
        {
          type: "text",
          text: "전체적인 뷰를 제공합니다.",
          lineEnd: true,
        },
        {
          type: "text",
          text: "관심지점에서 각종 측정(위치, 길이, 넓이,",
          lineEnd: true,
        },
        {
          type: "text",
          text: "체적, 고도 프로파일 등)을 신속히 수행하며 ",
          lineEnd: true,
        },
        {
          type: "text",
          text: "annotation 그리기 도구를 이용해",
          lineEnd: true,
        },
        {
          type: "text",
          text: "효율적으로 이슈를 점검할 수 있습니다.",
          lineEnd: false,
        },
      ],
      en: [
        {
          type: "text",
          text: "Provides an overall view of",
          lineEnd: true,
        },
        {
          type: "text",
          text: "the target objects in a ortho-image map.",
          lineEnd: true,
        },
        {
          type: "text",
          text: "Generate annotations with 3D measurements",
          lineEnd: true,
        },
        {
          type: "text",
          text: "(location, length, area, volume, elevation",
          lineEnd: true,
        },
        {
          type: "text",
          text: "profiles) at POI(point-of-interest)",
          lineEnd: true,
        },
        {
          type: "text",
          text: "for various issues(crack, rust, deformation etc.)",
          lineEnd: true,
        },
      ],
    },
  },
  inspection2DContent: {
    title: {
      ko: "정사영상 - 이미지 뷰",
      en: "Ortho - Image View",
    },
    issueMaker: {
      title: {
        ko: "1. 그리기 도구 기반 이슈 생성",
        en: "1. Tool-based Annotation Creation",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "이슈 생성 도구를 사용하여 다양한",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "어노테이션(마커, 선분, 사각형, 다각형,",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "텍스트)",
            lineEnd: false,
          },
          {
            type: "text",
            text: "을 그릴 수 있습니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "사이드바에 나타나는 이슈 정보에서 추가 정보를 작성해주세요.",
            lineEnd: true,
          },
        ],
        en: [
          {
            type: "text",
            text: "To create a new annotation,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "On the tool bar of ",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Map",
            lineEnd: false,
          },
          {
            type: "text",
            text: " window, click one of five annotation tools",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "(Marker, Line, Rectangles, Polygon, and Text) ",
            lineEnd: false,
          },
          {
            type: "text",
            text: "and specify",
            lineEnd: true,
          },
          {
            type: "text",
            text: "the position of annotation to be created on the ortho-image Annotation information displays on the side bar.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "In",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Issues, ",
            lineEnd: false,
          },
          {
            type: "text",
            text: "properties(name, size, opacity, types, quantity etc.)",
            lineEnd: true,
          },
          {
            type: "text",
            text: "can be edited.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "In",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Measurements, ",
            lineEnd: false,
          },
          {
            type: "text",
            text: "geometric information(position, length, area, volume, height profile) is to be calculated.",
            lineEnd: true,
          },
        ],
      },
    },
    issueManualMaker: {
      title: {
        ko: "2. 위치 기반 이슈 생성",
        en: "2. Coordinate-based Annotation Creation",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "값을 입력해 이슈를 수동으로 생성할 수도 있습니다.",
            lineEnd: true,
          },
          {
            type: "button",
            text: "xy로 입력하기",
            lineEnd: false,
          },
          {
            type: "button",
            text: "경위도로 입력하기",
            lineEnd: false,
          },
          {
            type: "text",
            text: "토글 버튼으로 좌표를 직접 입력합니다.",
            lineEnd: false,
          },
        ],
        en: [
          {
            type: "text",
            text: "To create a new annotation by adding coordinate information,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "On the side bar, click ",
            lineEnd: false,
          },
          {
            type: "button",
            text: "Creation",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Select one of five annotation tools and input coordinate information",
            lineEnd: true,
          },
          {
            type: "text",
            text: "where annotations are created.",
            lineEnd: true,
          },
        ],
      },
    },
    issueEditting: {
      title: { ko: "3. 이슈 편집", en: "3. Edit an issue" },
      description: {
        deleting: {
          ko: "수정 버튼을 클릭해 꼭지점을 추가하거나 삭제합니다.",
          en: "Click the Edit button to add or delete vertices.",
        },
        hidding: {
          ko: "보기 버튼을 클릭해 어노테이션 이름을 보거나 숨깁니다.",
          en: "Click the View button to show or hide the annotation name.",
        },
      },
    },
    highDefinitionImage: {
      title: {
        ko: "4. HD 이미지",
        en: "4. UAV Raw Image",
      },
      description: {
        toggle: {
          ko: [
            {
              type: "text",
              text: "우측에서 이미지 뷰어를 열고 닫을 수 있습니다.",
              lineEnd: true,
            },
          ],
          en: [
            {
              type: "text",
              text: "On the right side, UAV raw images can be accessed based on two methods.",
              lineEnd: true,
            },
            {
              type: "text",
              text: "Choose directly an image in the image list, or",
              lineEnd: true,
            },
          ],
        },
        shown: {
          ko: [
            {
              type: "text",
              text: "클릭한 위치의 고해상도 이미지가 이미지 뷰어에 나타납니다.",
              lineEnd: true,
            },
          ],
          en: [
            {
              type: "text",
              text: "On the tool bar of",
              lineEnd: false,
            },
            {
              type: "bold",
              text: "Map",
              lineEnd: false,
            },
            {
              type: "text",
              text: "window, click",
              lineEnd: false,
            },
            {
              type: "bold",
              text: "Image View",
              lineEnd: true,
            },
            {
              type: "text",
              text: "and then specify a location with which an UAV raw image is to",
              lineEnd: true,
            },
            {
              type: "text",
              text: "be associated.",
              lineEnd: true,
            },
          ],
        },
        zoom: {
          ko: "이미지를 확대하여 정밀한 점검이 가능합니다.",
          en: "Click Full Screen to perform a closer inspection.",
        },
        fav: {
          ko: "자주 보는 이미지를 즐겨 찾기에 추가해서 모아볼 수 있습니다.",
          en: "Click a star icon with a yellow color on each image to add frequently viewed images to your favorite.",
        },
      },
    },
    calInfo: {
      title: {
        ko: "5. 측정 정보",
        en: "5. Measurement Information",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "다차원(2D/3D)에 대한 전반적인 기하학 측정이 가능합니다.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "1. 위치(Position)",
            lineEnd: false,
          },
          {
            type: "text",
            text: ": 관심 지점에 대한 3D 위치(X, Y, Z)정보 추출",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "2. 길이(Length)",
            lineEnd: false,
          },
          {
            type: "text",
            text: ": 다중 벡터를 생성하여 2D/3D 총/구간 별 길이 계산",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "3. 면적(Area)",
            lineEnd: false,
          },
          {
            type: "text",
            text: ": 정형/비정형 형상에 대한 2D(평면) 및 3D(경사) 넓이 측정",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "4. 체적(Volume)",
            lineEnd: false,
          },
          {
            type: "text",
            text: ": 기준면을 조절하여 해당 부분에 대한 체적 계산",
            lineEnd: true,
          },
          {
            type: "text",
            text: " - 토공: 절토/성토량",
            lineEnd: true,
          },
          {
            type: "text",
            text: " - 콘크리트: 절토/성토량",
            lineEnd: false,
          },
        ],
        en: [
          {
            type: "text",
            text: "2D-3D geometric measurements is possible to be calculated.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "Position:",
            lineEnd: false,
          },
          {
            type: "text",
            text: " Extract 3D position (X, Y, Z) information at POI.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "Length:",
            lineEnd: false,
          },
          {
            type: "text",
            text: " Calculate total and sectional lengths of multiple vectors.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "Area:",
            lineEnd: false,
          },
          {
            type: "text",
            text: " Measure 2D (flat) and 3D (sloped) area for polygon geometry.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "Volume:",
            lineEnd: false,
          },
          {
            type: "text",
            text: " Calculate the volume(cut, fill, and net) of a polygon vector by adjusting the base plane.",
            lineEnd: true,
          },
        ],
      },
    },
    issueInfo: {
      title: {
        ko: "6. 이슈/측정 정보",
        en: "6. Annotation Information",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "이슈 사항별로 그룹핑된 간략한 이슈를 볼 수 있습니다.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "필터링 조건을 넣어 정사영상 위에서 특정 이슈만 볼 수 있습니다.",
            lineEnd: false,
          },
        ],
        en: [
          {
            type: "text",
            text: "To check annotation property including issue and measurement information,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "On the side bar, click",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Annotations.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "A summary of issues displays according to issue types.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Click one of issue categories and can access individual annotation",
            lineEnd: true,
          },
          {
            type: "text",
            text: "for more detail.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "If only specific annotations are wanted to be shown,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "click",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Filtering ",
            lineEnd: false,
          },
          {
            type: "text",
            text: "to select filtering options.",
            lineEnd: true,
          },
        ],
      },
    },
    linkContent: {
      title: {
        ko: [
          {
            type: "text",
            text: "7. 정사영상(2D) - 모델(3D) 연계",
            lineEnd: true,
          },
        ],
        en: [
          {
            type: "text",
            text: "7. Link between Orthoimage and",
            lineEnd: true,
          },
          {
            type: "text",
            text: "3D Model",
            lineEnd: true,
          },
        ],
      },
      description: {
        approach: {
          ko: " 정사영상과 모델을 연계해 모델 공간에서 관심 영역에 빠르게 접근합니다.",
          en: "To symbolize the position of the clicked point on Orthoimage and link the position to 3D Model,",
        },
        sync: {
          ko: [
            {
              type: "text",
              text: " 지도 하단 좌표계 박스에서 타켓 아이콘을 클릭하면",
              lineEnd: true,
            },
            {
              type: "text",
              text: " 2D/3D 화면에서 포인트가 동기화됩니다.",
              lineEnd: false,
            },
          ],
          en: [
            {
              type: "text",
              text: "On the Map window, click",
              lineEnd: false,
            },
            {
              type: "bold",
              text: "Position Link",
              lineEnd: false,
            },
            {
              type: "text",
              text: "at the bottom and specify",
              lineEnd: true,
            },
            {
              type: "text",
              text: "a point on Orthoimage.",
              lineEnd: true,
            },
            {
              type: "text",
              text: "The Target icon appears on Orthoimage and synchronize ",
              lineEnd: true,
            },
            {
              type: "text",
              text: "the point on 3D Model.",
              lineEnd: false,
            },
          ],
        },
      },
    },
    layerInfo: {
      title: {
        ko: "8. 레이어 정보",
        en: "8. Layer Information",
      },
      description: {
        ko: [
          {
            type: "bold",
            text: "주 레이어",
            lineEnd: false,
          },
          {
            type: "text",
            text: "(정사영상, DSM)는 하나만 선택 가능하며",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "GIS 레이어, 보조레이어",
            lineEnd: false,
          },
          {
            type: "text",
            text: "(어노테이션)는 복수 선택이 가능합니다.",
            lineEnd: false,
          },
        ],
        en: [
          {
            type: "text",
            text: "To integrate GIS layers with Orthoimage,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "On the side bar, click",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Layers.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Only one Primary Layer (Orthoimage or DSM) can be selected.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Multiple GIS and Secondary Layer can be selected.",
            lineEnd: true,
          },
        ],
      },
    },
    report: {
      title: {
        ko: "9. 리포트",
        en: "9. Report",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "프로젝트 정보, 이슈사항 배치도, 요약 정보가 담긴 pdf를 출력합니다.",
            lineEnd: true,
          },
        ],
        en: [
          {
            type: "text",
            text: "To generate the issue report,",
            lineEnd: true,
          },
          {
            type: "text",
            text: "On the side bar, click",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Report.",
            lineEnd: true,
          },
          {
            type: "text",
            text: "Enter properties related to report and click",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "Auto-generated Report.",
            lineEnd: true,
          },
        ],
      },
    },
  },
  inspection3DSection: {
    title: {
      ko: [
        {
          type: "text",
          text: "드론 고해상도 이미지",
          lineEnd: true,
        },
        {
          type: "text",
          text: "/ 3D(모델) 기반",
          lineEnd: true,
        },
        {
          type: "text",
          text: "점검 어플리케이션",
          lineEnd: false,
        },
      ],
      en: [
        {
          type: "text",
          text: "Drone HD Image",
          lineEnd: true,
        },
        {
          type: "text",
          text: "/ 3D(model)",
          lineEnd: true,
        },
        {
          type: "text",
          text: "based inspection",
          lineEnd: true,
        },
        {
          type: "text",
          text: "application",
          lineEnd: false,
        },
      ],
    },
    description: {
      ko: [
        {
          type: "text",
          text: "기가바이트 단위 대용량 3차원 실감 모델을",
          lineEnd: true,
        },

        {
          type: "text",
          text: "신속하게 가시화하여 직관적인 3차원",
          lineEnd: true,
        },
        {
          type: "text",
          text: "공간정보 분석이 가능합니다.",
          lineEnd: false,
        },
      ],
      en: [
        {
          type: "text",
          text: "Quickly visualize gigabyte-scale",
          lineEnd: true,
        },
        {
          type: "text",
          text: "three-dimensional immersive models",
          lineEnd: true,
        },
        {
          type: "text",
          text: "for intuitive three-dimensional",
          lineEnd: true,
        },
        {
          type: "text",
          text: "spatial information analysis.",
          lineEnd: false,
        },
      ],
    },
  },
  inspection3DContent: {
    title: {
      ko: "포인트 클라우드 - 이미지 뷰",
      en: "3D Point Cloud - 2D Images",
    },
    appearance: {
      title: {
        ko: "1. Appearance",
        en: "1. Appearance",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "모델을 바라보는 화각과 모델을 구성하고 있는",
            lineEnd: false,
          },
          {
            type: "bold",
            text: "포인트",
            lineEnd: false,
          },
          {
            type: "text",
            text: "의 개수, 크기, 테두리",
            lineEnd: true,
          },
          {
            type: "text",
            text: "(선명함)를 설정합니다.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "마우스 휠",
            lineEnd: false,
          },
          {
            type: "text",
            text: "을 조작해 줌 설정이 가능합니다.",
            lineEnd: false,
          },
        ],
        en: [
          {
            type: "text",
            text: "Set the angle of view of the model and the number, size, and border (sharpness) of the points that make up the model.",
            lineEnd: true,
          },
          {
            type: "bold",
            text: "You can set the zoom by operating the mouse wheel.",
            lineEnd: true,
          },
        ],
      },
    },
    tools: {
      title: {
        ko: "2. Tools",
        en: "2. Tools",
      },
      description: {
        summary: {
          ko: [
            {
              type: "text",
              text: "측정 도구를 이용해 선택한 지점의 각도, 거리, 면적, 높이, 원의 반지름,",
              lineEnd: true,
            },
            {
              type: "text",
              text: "방위각을 계산하고 고도 프로파일을 확인할 수 있습니다.",
              lineEnd: false,
            },
          ],
          en: [
            {
              type: "text",
              text: "Calculate angles, distances, areas, heights, radius of circles, and azimuths of selected points and view elevation profiles using measurement tools.",
              lineEnd: true,
            },
          ],
        },
        degree: {
          ko: "삼각형의 각도를 구합니다.",
          en: "Find the angles of a triangle.",
        },
        position: {
          ko: "중부원점 좌표를 구합니다.",
          en: "Find the coordinates of the midpoint of a circle.",
        },
        drawing: {
          ko: [
            {
              type: "text",
              text: "클릭한 지점을 연결하여 선을 그립니다.",
              lineEnd: true,
            },
            {
              type: "text",
              text: "완료하려면 마우스 오른쪽 버튼을 클릭하세요.",
              lineEnd: false,
            },
          ],
          en: [
            {
              type: "bold",
              text: "Draw line segments. Right-click on the screen to finish.",
              lineEnd: true,
            },
            {
              type: "text",
              text: "Draw a line segment.",
              lineEnd: false,
            },
          ],
        },
        height: {
          ko: "빗면에 대한 높이값을 구합니다.",
          en: "Find the height of the hypotenuse.",
        },
        radius: {
          ko: "원의 반지름을 구합니다.",
          en: "Find the radius of the circle.",
        },
        direction: {
          ko: "방위각을 구합니다.",
          en: "Find the azimuthal angle.",
        },
        surface: {
          ko: "면을 구성하는 선분의 길이와 중심 좌표를 구할 수 있습니다.",
          en: "Determine the lengths and center coordinates of the line segments that make up the plane.",
        },
        deletingAnnotation: {
          ko: "모든 어노테이션을 삭제합니다.",
          en: "Delete all annotations.",
        },
        targetHeight: {
          ko: [
            {
              type: "bold",
              text: "선택한 지점의 고도 프로파일을 확인합니다.",
              lineEnd: true,
            },
          ],
          en: [
            {
              type: "bold",
              text: "View the elevation profile of a selected point.",
              lineEnd: true,
            },
          ],
        },
      },
    },
    scene: {
      title: {
        ko: "3. Scene",
        en: "3. Scene",
      },
      description: {
        ko: [
          {
            type: "text",
            text: "포인트 클라우드, 어노테이션에 대한 측정값 등을 폴더 구조 형태로",
            lineEnd: true,
          },
          {
            type: "text",
            text: "확인할 수 있습니다.",
            lineEnd: true,
          },
        ],
        en: [
          {
            type: "text",
            text: "You can view point clouds, measurements for annotations, ",
            lineEnd: true,
          },
          {
            type: "text",
            text: "and more in a folder structure.",
            lineEnd: false,
          },
        ],
      },
    },
  },
  multiViewSection: {
    title: {
      ko: "다중뷰",
      en: "Multi-view",
    },
    description: {
      ko: [
        {
          type: "text",
          text: "동일한 지역을 레이어별로 분할(2/4/9)",
          lineEnd: true,
        },
        {
          type: "text",
          text: "비교하여 현장 상황과 시설물 노후화를",
          lineEnd: true,
        },
        {
          type: "text",
          text: "효과적으로 모니터링 할 수 있습니다.",
          lineEnd: true,
        },
        {
          type: "text",
          text: "동일한 지역 프로젝트들을 한 그룹으로",
          lineEnd: true,
        },
        {
          type: "text",
          text: "관리하는 경우 시계열적 변화를",
          lineEnd: true,
        },
        {
          type: "text",
          text: "추적할 수 있습니다.",
          lineEnd: true,
        },
      ],
      en: [
        {
          type: "text",
          text: "Split the same area into layers(2/4/9)",
          lineEnd: true,
        },
        {
          type: "text",
          text: "to compare site conditions and",
          lineEnd: true,
        },
        {
          type: "text",
          text: "facility deterioration.",
          lineEnd: true,
        },
        {
          type: "text",
          text: "If you manage projects",
          lineEnd: true,
        },
        {
          type: "text",
          text: "in the same area as a group,",
          lineEnd: true,
        },
        {
          type: "text",
          text: "you can track time series changes.",
          lineEnd: true,
        },
      ],
    },
  },
};

// {
//   type: "text",
//   text: "2D(이미지) /",
//   lineEnd: true,
// },
