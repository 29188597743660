import {
  RiLayoutColumnLine,
  RiLayoutGridLine,
  RiLayoutRowLine,
} from "react-icons/ri";
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { MarkerColor } from "../components/SideNav/annotationInformaition/AnnotationInformation";
import { ReactNode } from "react";
import { textParser } from "../utils/textParser";
import { errorNotice } from "./locale";

export type ChangePasswordMode =
  | typeof SEND_CODE
  | typeof VERIFY_CODE
  | typeof CHANGE_PASSWORD;

export const COGNITO_IDENTITYPOOL_ID = "IdentityPoolId";
export const COGNITO_USERPOOL_ID = "UserPoolId";
export const COGNITO_USERPOOL_WEBCLIENT_ID = "UserPoolWebClientId";
export const API_GATEWAY_URL = "Endpoint";
export const S3_API_URL = "S3_Endpoint";
export const DATA_BUCKET_NAME = "BucketName";
export const TIMESERIES_ENDPOINT = "HTTPS_TimeseriesApiUrl";
export const CAL_API_ENDPOINT = "Calc_Api_Endpoint";
export const CAL_EFS_API_ENDPOINT = "Efs_Calc_Api_Url";
export const POTREE_PROXY_ENDPOINT = "Potree_Url";
export const CAPTURE_CLOUDFRONT_URL = "Cloudfront_Url";
export const PUBLIC_STORAGE = "https://st-landing.ds-develop.com";
export const MAPPING_REQUEST_URL = "Ecs_Apigw_Url";

export const PARAMETERS = [
  COGNITO_IDENTITYPOOL_ID,
  COGNITO_USERPOOL_ID,
  COGNITO_USERPOOL_WEBCLIENT_ID,
  API_GATEWAY_URL,
  DATA_BUCKET_NAME,
  S3_API_URL,
  POTREE_PROXY_ENDPOINT,
  TIMESERIES_ENDPOINT,
  CAL_API_ENDPOINT,
  CAPTURE_CLOUDFRONT_URL,
];
export const INFRA_CLASS = [
  { ko: "일반 객체", en: "Regular Object" },
  { ko: "코리더 객체", en: "Corridor Object" },
  { ko: "면 객체", en: "Face Obejct" },
  { ko: "수직 객체", en: "Vertical Object" },
  { ko: "기타", en: "Others" },
];
export const MARKER_COLORS: MarkerColor[] = [
  "red",
  "yellow",
  "green",
  "skyblue",
  "blue",
  "brown",
];
export const LATLNG_SELECT_ITEMS = [
  { value: "KOREA2000", label: "Korea2000" },
  { value: "GOOGLE", label: "Google Mercator" },
  { value: "WSG", label: "WSG 84 / LonLat" },
  { value: "UTM", label: "UTM" },
];
export const ROLE_SELECT_ITEMS = [
  {
    label: "inspector",
    value: "inspector",
  },
  {
    label: "viewer",
    value: "viewer",
  },
];

export const ISSUE_GRADE_ITEMS = {
  severity: { ko: "심각도", en: "Severity" },
  importance: { ko: "중요도", en: "Priority" },
  urgenty: { ko: "긴급도", en: "Urgency" },
};

export const INSPECTION2D_PAGE_CATEGORY = {
  inspection2D: { ko: "정사영상", en: "2D Image" },
  inspection3D: { ko: "3D 모델", en: "3D Model" },
  timeSerise: { ko: "다중 뷰", en: "Multi-view" },
};

export const ISSUE_GRADE_VALUE_COLOR = [
  { grade: "0", color: "#fff" },
  { grade: "1", color: "#F5BB3C" },
  { grade: "2", color: "#F59700" },
  { grade: "3", color: "#C6612A" },
  { grade: "4", color: "#E63F30" },
  { grade: "5", color: "#BE3C30" },
];

type Tab = {
  ko: string;
  en: string;
};

export type CustomTabs = Record<string, Tab>;

export const MANAGE_DRONE_IMG_TABS = {
  UPLOADED_IMGS: { ko: "이미지 리스트", en: "Images" },
  PRE_UPLOAD_IMGS: { ko: "이미지 추가", en: "Image Uploading" },
};

export const MANAGE_ANNOTATION_TABS = {
  ISSUE_INFORMATION: { ko: "이슈", en: "Issue" },
  MEASUARMENT_INFORMATION: { ko: "측정", en: "Measurements" },
};

export const MANAGE_CROSS_SECTION_TABS = {
  LONGITUDINAL: { ko: "단면", en: "Elevation Profile" },
  PERPENDICULAR: { ko: "종횡단면", en: "Cross Section" },
};

export const MANAGE_OTHER_DATA_TABS = {
  REFERENCE_DATA: { ko: "참고", en: "Etc Files" },
  OVERLAPPING_DATA: { ko: "레이어", en: "Overlay Layers" },
};

export const MANAGE_ANNOTATIONS_TABS = {
  ISSUE_INFORMATION: { ko: "이슈 리스트", en: "Issue Lists" },
  MEASUARMENT_INFORMATION: { ko: "측정 리스트", en: "Measurement Lists" },
};

export const MANAGE_PROJECT_TABS = {
  PROJECT: { ko: "프로젝트", en: "Project" },
  MAPPING: { ko: "매핑", en: "Mapping" },
};

export const LAYER_DESCRIPTION_NAV_ITEMS = {
  calInfoOptions: ["line", "rectangle", "polygon", "circle"],
  calPathInfoOptions: ["line", "rectangle", "polygon"],
  amountOfSectionOptions: ["rectangle", "polygon"],
  longitudinalSectionOptions: ["rectangle", "polygon"],
  crossSectionOptions: ["line"],
};
export const AUTH_GRADE = {
  GUEST: "guests",
  USER: "users",
  ADMIN: "admin",
  ROAD_DETECTION: "roadDetection",
};
export const SIGNIN_ERROR = {
  UserNotConfirmedException: {
    name: "UserNotConfirmedException",
    message: {
      ko: "인증되지 않은 사용자입니다.",
      en: "Email Confirmation is not completed.",
    },
  },
  NotAuthorizedException: {
    name: "NotAuthorizedException",
    message: { ko: "비밀번호가 일치하지 않습니다.", en: "Wrong Password" },
  },
  UserNotFoundException: {
    name: "UserNotFoundException",
    message: { ko: "존재하지 않는 사용자입니다.", en: "Wrong User ID" },
  },
  ExpiredCodeError: {
    name: "ConfirmForgotPassword",
    message: { ko: "만료된 코드입니다.", en: "The code has expired" },
  },
  EmailNotConfirmCodeError: {
    name: "UserNotConfirmedException",
    message: {
      ko: "이메일 인증을 완료해주세요.",
      en: "Please Complete the email verification",
    },
  },
};

export const EMAIL_ERROR = {
  UserNotFoundException: SIGNIN_ERROR.UserNotFoundException,
};

export const SIGNUP_ERROR = {
  UnexpectedLambdaError: {
    name: "UnexpectedLambdaException",
    message: {
      ko: "이메일 인증에 성공했으나, 예기치 않은 예외 발생.",
      en: "Email verification succeeded, but an unexpected exception occurred",
    },
  },
  EmailCodeMismatchError: {
    name: "CodeMismatchException",
    message: {
      ko: "인증번호가 일치하지 않습니다.",
      en: "The verification code does not match",
    },
  },
};

export const CHANGE_PW_CODE_ERROR = {
  CodeMismatchException: SIGNUP_ERROR.EmailCodeMismatchError,
  PassWordChangeFailedError: {
    name: "PassWordChangeFailedError",
    message: {
      ko: "비밀번호 변경에 실패했습니다.",
      en: "Password change failed",
    },
  },
};

export const QUERY_OPTIONS = {
  retry: 2,
  refetchOnWindowFocus: false,
  staleTime: 10 * 60 * 1000,
  cacheTime: 30 * 60 * 1000,
};
export const DEFAULT_MAP_LOCATION = {
  lng: 126.97,
  lat: 37.56,
};

export const BIAS_ZOOM7 = 2.2;
export const BIAS_ZOOM11 = 0.015;

export const BIAS_LAT_LON_RATIO = 1.25;

export const DEFAULT_ZOOMIMAGE_LOCATION = {
  lng: 127,
  lat: 38,
};

export const MODAL_TYPE_STYLES = {
  hasTable: {
    width: "880px",
    height: "580px",
  },
  singleInput: {
    width: "370px",
    height: "190px",
  },
  delete: {
    width: "370px",
    height: "120px",
  },
};

export const TIMESERISE_QUERY_KEYS = [
  "getTimeseriesList",
  "getNotIncludedTimeseriesList",
];

export const RANDOM_COLOR = ["blue", "red", "brown", "green", "black"];

export const TIMESERISE_LAYOUT_BUTTONS = [
  {
    label: 0,
    icon: RiLayoutColumnLine,
    columns: 2,
    rows: 1,
    buttonSize: 25,
  },
  {
    label: 1,
    icon: RiLayoutRowLine,
    columns: 1,
    rows: 2,
    buttonSize: 25,
  },
  {
    label: 2,
    icon: RiLayoutGridLine,
    columns: 2,
    rows: 2,
    buttonSize: 25,
  },
  {
    label: 3,
    icon: TfiLayoutGrid3,
    columns: 3,
    rows: 3,
    buttonSize: 24,
  },
];

export const SIGNUP_FIELD = [
  { ko: "설계", en: "Design" },
  { ko: "시공", en: "Construction" },
  { ko: "유지보수", en: "Maintenance" },
  { ko: "안전진단", en: "Safety Assessment" },
  { ko: "측량", en: "Surveying" },
  { ko: "농업", en: "Agriculture" },
  { ko: "환경", en: "Environment" },
  { ko: "도시계획", en: "Urban Planning" },
  { ko: "드론운용", en: "Drone Operation" },
  { ko: "제조", en: "Manufacturing" },
  { ko: "운송", en: "Transportation" },
  { ko: "정보통신", en: "Information and Communication" },
  { ko: "공공행정", en: "Public Administration" },
  { ko: "자원개발", en: "Resource Development" },
  { ko: "기술 서비스", en: "Technical Service" },
  { ko: "기타", en: "Others" },
];

export const COORDINATE_NAMES = {
  5185: { ko: "서부원점", en: "West Belt" },
  5186: { ko: "중부원점", en: "Central Belt" },
  5187: { ko: "동부원점", en: "East Belt" },
  32648: { ko: "UTM", en: "UTM" },
};

export const DOWNLOAD_ICON = `
  <?xml version="1.0" encoding="utf-8"?>
  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 16L11.6464 16.3536L12 16.7071L12.3536 16.3536L12 16ZM12.5 4C12.5 3.72386 12.2761 3.5 12 3.5C11.7239 3.5 11.5 3.72386 11.5 4L12.5 4ZM5.64645 10.3536L11.6464 16.3536L12.3536 15.6464L6.35355 9.64645L5.64645 10.3536ZM12.3536 16.3536L18.3536 10.3536L17.6464 9.64645L11.6464 15.6464L12.3536 16.3536ZM12.5 16L12.5 4L11.5 4L11.5 16L12.5 16Z" fill="#fff"/>
  <path d="M5 21H19" stroke="#fff"/>
  </svg>`;

export const FILE_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="20px"
    height="20px"
  >
    <path d="M 6 3 L 6 29 L 26 29 L 26 9.59375 L 25.71875 9.28125 L 19.71875 3.28125 L 19.40625 3 Z M 8 5 L 18 5 L 18 11 L 24 11 L 24 27 L 8 27 Z M 20 6.4375 L 22.5625 9 L 20 9 Z M 11 13 L 11 15 L 21 15 L 21 13 Z M 11 17 L 11 19 L 21 19 L 21 17 Z M 11 21 L 11 23 L 21 23 L 21 21 Z"  strokeWidth="1" />
  </svg>;
`;

export const TEXT_C_ICON = `
  <svg xmlns="http://www.w3.org/2000/svg"
    height="14"
    width="10.5"
    viewBox="0 0 384 512">
    <path d="M329.1 142.9c-62.5-62.5-155.8-62.5-218.3 0s-62.5 163.8 0 226.3s155.8 62.5 218.3 0c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3c-87.5 87.5-221.3 87.5-308.8 0s-87.5-229.3 0-316.8s221.3-87.5 308.8 0c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0z"/>
  </svg>
`;

export const CHANNELTALK_KEY = "dafd7567-df37-495c-afc3-31c9e9e33af7";

export type ValueOf<T> = T[keyof T];

export const ANNOTATION_TYPE = {
  marker: "marker",
  line: "line",
  polygon: "polygon",
  text: "text",
  rectangle: "rectangle",
};

export const PROJECT_CONDITION: {
  BEFORE: "before";
  PROCESSING: "on_processing";
  COMPLETED: "completed";
  FAILED: "failed";
  REQUESTED: "requested";
  REVIEWING: "reviewing";
} = {
  BEFORE: "before",
  PROCESSING: "on_processing",
  COMPLETED: "completed",
  FAILED: "failed",
  REQUESTED: "requested",
  REVIEWING: "reviewing",
};

export const FIVE_GIGABYTE_AMOUNT = 5368709120;

export type ModalType = {
  title: string;
  isDark?: boolean;
  doNotShowDay?: number;
  component: ReactNode;
};

export const makeModalConstants = (locale: "en" | "ko"): ModalType[] => [
  {
    title: errorNotice.title[locale],
    component: textParser(errorNotice.content[locale]),
  },
];

export function getNoticeObjFromStorageObj(
  key: string
): Record<string, number> {
  const stringiedObj = localStorage.getItem(key);
  if (stringiedObj) {
    return JSON.parse(stringiedObj);
  } else {
    return {};
  }
}

export function setNoticeObjToStorageObj(
  key: string,
  noticeObj: Record<string, number>
) {
  const stringifiedNoticeObj = JSON.stringify(noticeObj);
  localStorage.setItem(key, stringifiedNoticeObj);
}

export const NETWORK_REQUEST_BATCH_LIMIT = 300;
export const NETWORK_REQUEST_GRAPH_BATCH_LIMIT = 10;

export const CROSS_LONGITUDE_GRAPH = {
  MIN_POINT_STEP: 1,
  MIN_LINE_STEP: 5,
  MAX_STEP: 100,
  STEP_INCREMENT: 5,
};

export const DXF_GRAPH = {
  MIN_POINT_STEP: 1,
  MIN_LINE_STEP: 20,
  LEFT: 10,
  RIGHT: 10,
  START_POINT: 0,
};

export const MARKER_PX_SIZE = [
  { label: "12px", value: 12 },
  { label: "13px", value: 13 },
  { label: "14px", value: 14 },
  { label: "15px", value: 15 },
  { label: "16px", value: 16 },
  { label: "17px", value: 17 },
  { label: "18px", value: 18 },
  { label: "19px", value: 19 },
  { label: "20px", value: 20 },
  { label: "21px", value: 21 },
  { label: "22px", value: 22 },
  { label: "23px", value: 23 },
  { label: "24px", value: 24 },
];

export const EMAIL_REG_EXP =
  /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+(\.[A-Za-z0-9\-]+)*$/;

export const SEND_CODE = "Send Code";
export const VERIFY_CODE = "Verify Code";
export const CHANGE_PASSWORD = "Change Password";
export const CHANGE_PASSWORD_STEPS: ChangePasswordMode[] = [
  SEND_CODE,
  VERIFY_CODE,
  CHANGE_PASSWORD,
];

export type PROCESS_STATUS =
  | "COMPLETE"
  | "PROCESSING"
  | "BEFORE"
  | "ERROR"
  | "DEFAULT";

export type PROCESS_STATUS_SMALL =
  | "complete"
  | "processing"
  | "before"
  | "error"
  | "default";

export const STATUS_COLORS: Record<PROCESS_STATUS, string> = {
  COMPLETE: "#00cc66",
  PROCESSING: "#ffcc00",
  BEFORE: "#aaaaaa",
  ERROR: "#ff0000",
  DEFAULT: "transparent",
};

export const statusColorMap: Record<PROCESS_STATUS_SMALL, string> = {
  complete: STATUS_COLORS.COMPLETE,
  processing: STATUS_COLORS.PROCESSING,
  before: STATUS_COLORS.BEFORE,
  default: STATUS_COLORS.DEFAULT,
  error: STATUS_COLORS.ERROR,
};
