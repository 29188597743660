export const landingLocaleString = {
  home: {
    section1: {
      title: {
        ko: "드론 기반 인프라 자산관리 플랫폼",
        en: "Drone-based Infrastructure Asset Management Platform ",
      },
      subTitle: {
        ko: "드론 스퀘어에서 제공하는 다양한 어플리케이션을 통해 프로젝트를 효율적으로 관리해주세요.",
        en: "Efficiently manage assets in various fields of smart land using drone data that reflects the latest on-site conditions",
      },
    },
    section2: {
      title: {
        line1: {
          ko: "드론 스퀘어를 도입해",
          en: "Transform spatial data",
        },
        line2: {
          ko: "공간 데이터를",
          en: "into valuable business intelligence",
        },
        line3: {
          ko: "가치있는 비즈니스 인텔리전스로 전환하세요.",
          en: "by using DroneSquare.",
        },
      },
    },
    section3: {
      title: {
        line1: {
          ko: "드론 스퀘어로 인프라 자산 디지털 전환,",
          en: "Transforming Infrastructure Asset into DroneSquare,",
        },
        line2: {
          ko: "드론 스퀘어로부터 인프라 자산 스마트 관리/분석",
          en: "DroneSquare into Predictive Maintenance",
        },
      },
      subTitle1: {
        line1: {
          ko: "대량의 드론 공간데이터가 프로젝트 단위별로",
          en: "Providing a project management tool that enables large volumes of drone spatial data to be stored",
        },
        line2: {
          ko: "웹 플랫폼에 저장되고 시계열적으로 연계되어 관리될 수 있도록 제공합니다.",
          en: "on a web platform by a project unit and managed in the spatio-temporal domain.",
        },
      },
      subTitle2: {
        line1: {
          ko: "3D 모델 상에서 대상 객체/현장에 대한 전체적인 뷰를 제공하고,",
          en: "Allowing for more detailed inspections by providing an overall view of target objects/sites and",
        },
        line2: {
          ko: "이슈 생성 및 측정을 신속하게 수행할 수 있으며,",
          en: "enabling quick issue creation and measurement on those 3D realistic model space.",
        },
        line3: {
          ko: "관심지점에 HD 이미지가 연계되어 더 세밀한 점검이 가능합니다.",
          en: "",
        },
      },
      subTitle3: {
        line1: {
          ko: "공간 빅데이터를 공유하고 쉽게 활용할 수 있는 체계를 제공하여",
          en: "Supporting an optimal decision-making by providing a solution for sharing and easily utilizing",
        },
        line2: {
          ko: "일 단위 작업에서 시간 단위 작업으로 전환을 가능하게 하며 최적의 의사결정을 지원합니다.",
          en: "spatial big data, resulting in the transition from daily tasks to hourly tasks.",
        },
      },
    },
  },
  accurate: {
    section1: {
      title: {
        line1: {
          ko: "최신 현장 상황을 반영한 드론 데이터를 기반으로 한 웹 솔루션을 통해",
          en: "Optimizing an infrastructure asset management process with DroneSquare",
        },
        line2: {
          ko: "스마트 도시의 다양한 분야에서 자산관리 효율성을 최적화하세요.",
          en: "using drone data reflecting the latest site conditions",
        },
      },
    },
    section2: {
      title: {
        line1: {
          ko: "디지털 환경에서 대량의 드론데이터 구축. 이해하기 쉬운 프로젝트 관리. 더 빠르고 정확한 2D/3D 점검.",
          en: "Enable user-friendly project management, quick and accurate 2D/3D inspections",
        },
        line2: {
          ko: "최신 검사 보고서. 공간 정보 중첩/가시화",
          en: "and intuitive multi-layer GIS/BIM overlays in a cloud environment",
        },
      },
      subTitle1: {
        ko: "효과적인 프로젝트 관리",
        en: "Time-series Project Management",
      },
      subItem1: {
        line1: {
          ko: "대량의 프로젝트 생성/관리/편집/검색",
          en: "Don’t worry about organizing historical projects ",
        },
        line2: {
          ko: "이미지 매핑 정보 제공",
          en: "and their related data",
        },
        line3: {
          ko: "정사영상, 포인트 클라우드, 매쉬, DSM 모델 다운로드",
          en: "(images, orthomosaics, 3D model, video, any files)",
        },
        line4: {
          ko: "프로젝트 그룹핑을 이용한 시계열적 점검",
          en: "",
        },
      },
      subTitle2: {
        ko: "드론 이미지(2D) - 모델(3D) 연계 점검",
        en: "Elevating Your Inspection",
      },
      subItem2: {
        line1: {
          ko: "드론 이미지 매핑 성과물과 고해상도 이미지의 기하학적인 연계",
          en: "Load spatial data and immediately perform time-",
        },
        line2: {
          ko: "3차원 모델 공간의 지형지물 측정 (위치, 체적, 종/횡단면도)",
          en: "and cost-effective multi-dimensional inspection",
        },
        line3: {
          ko: "관심 영역에 대한 빠른 annotation",
          en: "",
        },
        line4: {
          ko: "간이 점검 리포트 제공",
          en: "",
        },
      },
    },
  },
  survey: {
    section1: {
      title: {
        line1: {
          ko: "전통적인 측량 방식을 대체하여 현장을 어디로든 가져와",
          en: "Providing the full drone mapping package to create topographic maps,",
        },
        line2: {
          ko: "효과적인 3차원 디지털 맵을 생성합니다.",
          en: "and replacing time- and cost-inefficient traditional survey  ",
        },
      },
    },
    section2: {
      subTitle1: {
        line1: {
          ko: "고객의 요구사항대로 드론 운용 전문가가 직접 대상 지역 촬영부터 고정밀 지도 제작까지",
          en: "Performing highly accurate drone survey on your worksite",
        },
        line2: {
          ko: "신속하고 정확하게 end-to-end 드론 솔루션을 제공합니다.",
          en: "",
        },
      },
    },
  },
  footer: {
    companyInfo: {
      ko: "회사 정보",
      en: "Company Information",
    },
    terms: {
      ko: "이용약관",
      en: "Terms of Use",
    },
    privacyPolicy: {
      ko: "개인정보 처리방침",
      en: "Privacy Policy",
    },
    partnershipInquiry: {
      ko: "제휴/이용 문의",
      en: "Partnership/Inquiry",
    },
    headquarters: {
      ko: "본사 | 경상남도 창원시 성산구 창이대로 510, 403호(용호동,경남보훈회관)",
      en: "Head Office | #403, 510, Changi-daero, Uichang-gu, Chang-won-si, Gyeongsangnam-do, Korea",
    },
    seoulOffice: {
      ko: "서울지사 | 서울특별시 송파구 정의로8길 13 (수성위너스)",
      en: "Seoul Office | 13, Jeongui-ro 8-gil, Songpa-gu, Seoul, Republic of Korea",
    },
    phoneNumber: {
      ko: "대표전화 02-2102-5800",
      en: "Main Phone (+82) 02-2102-5800",
    },
  },
};
